import React from "react";
import { useAuth } from "../../contexts/AuthContext";
import { Container, Row, Col, Button } from "react-bootstrap";
import Logo from "../../logo.svg";
import { useHistory, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import "./Login.css";

export default function Login() {
  const { signInWithGoogle, logout } = useAuth();
  let history = useHistory();
  let location = useLocation();

  let { from } = location.state || { from: { pathname: "/catalog-editor" } };

  return (
    <Container className="loginPanelWrapper">
      <Row className="justify-content-center loginPanelRow align-items-center">
        <Col md="4" className="text-center">
          <div className="loginPanel">
            <img src={Logo} alt="Presentin" width="200" height="100" />
            <div className="d-grid gap-2">
              <Button
                className="loginButton"
                size="lg"
                onClick={() =>
                  signInWithGoogle()
                    .then((credentials) => {
                      if (
                        credentials.user.email ===
                          "jessicaannascimento@gmail.com" ||
                        credentials.user.email === "souza.msmauricio@gmail.com"
                      ) {
                        history.replace(from);
                      } else {
                        logout();
                      }
                    })
                    .catch((error) => console.log(error))
                }>
                <FontAwesomeIcon icon={faGoogle} /> Entrar com Google
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
