import React, { useState, useRef } from 'react'
import './CatalogEditor.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import Logo from '../../logo.svg'
import { Container, Navbar, Row, Col, Form, ProgressBar, Spinner, Button, Image, Table } from 'react-bootstrap'
import { storage } from '../../firebase';
import { ref, uploadBytesResumable, getDownloadURL } from '@firebase/storage';
import { setDoc, doc, deleteDoc } from '@firebase/firestore';
import db from '../../firebase';
import createUID from '../createUID'
import photoPlaceholder from './placeholder.png'
export default function CatalogEditor(props) {

    const [addPanel, toggleAddPanel] = useState(false)
    const [productPhoto, setProductPhoto] = useState(photoPlaceholder)
    const [progress, setProgress] = useState(0)
    const [isSubmiting, setSubmitStatus] = useState(false)
    const [isEditting, setIsEdittingStatus] = useState(false)
    const [edittingId, setEdittingId] = useState(null)

    const refImageUploader = useRef(null);
    const productNameRef = useRef(null);
    const productPriceRef = useRef(null)

    function handleEdit(product) {
        setIsEdittingStatus(true)
        setProductPhoto(product.photo)
        setEdittingId(product.id)
        productNameRef.current.value = product.name
        productPriceRef.current.value = product.price
    }

    const handleDelete = async (productId) => {

        await deleteDoc(doc(db, "products", productId));
    }

    function handleUploadImageClick() {
        refImageUploader.current.click();
    }

    function handleUploadImage(e) {
        if (e.target.files[0]) {

            const imageRef = ref(storage, `images/${e.target.files[0].name}`)

            const uploadTask = uploadBytesResumable(imageRef, e.target.files[0]);

            uploadTask.on('state_changed',
                (snapshot) => {
                    // Observe state change events such as progress, pause, and resume
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setProgress(progress)
                    switch (snapshot.state) {
                        case 'paused':
                            break;
                        case 'running':
                            break;
                        default:
                            break;
                    }
                },
                (error) => {
                    // Handle unsuccessful uploads
                },
                () => {
                    // Handle successful uploads on complete
                    // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        setProductPhoto(downloadURL)
                    });
                }
            )
        }
    }

    const handleSaveProduct = async (event) => {
        setSubmitStatus(true)
        event.preventDefault()
        let editProduct = {
            id: edittingId,
            name: event.target.name.value,
            price: event.target.price.value,
            photo: productPhoto,
        }
        await setDoc(doc(db, "products", editProduct.id), editProduct)
        setSubmitStatus(false)
        setIsEdittingStatus(false)
        setEdittingId(null)
        setProductPhoto(photoPlaceholder)
        event.target.name.value = ""
        event.target.price.value = ""
    }

    const handleSubmit = async (event) => {
        setSubmitStatus(true)
        event.preventDefault()
        let newProduct = {
            id: createUID(),
            name: event.target.name.value,
            price: event.target.price.value,
            photo: productPhoto,
        }
        await setDoc(doc(db, "products", newProduct.id), newProduct)
        setSubmitStatus(false)
        setProductPhoto("https://firebasestorage.googleapis.com/v0/b/presentin-f5481.appspot.com/o/images%2Fplaceholder.png?alt=media&token=d9b0a4b6-6ceb-405d-8f24-2bf1eb1c3b03")
        event.target.name.value = ""
        event.target.price.value = ""
    }

    return (
        <div>

            <Navbar variant="light" className="navbarWrapper">
                <Container>
                    <img
                        alt="Presentin"
                        src={Logo}
                        width="120"
                        height="40"
                    />
                    <span className="tagLine">Adicione produtos.</span>
                </Container>
            </Navbar>
            <Container>
                <Row className="justify-content-center">
                    <Col md="4">
                        <div className="d-grid gap-2">
                            <Button className="addButton" onClick={() => toggleAddPanel(!addPanel)}>Adicionar produto
                            </Button>
                        </div>
                        {addPanel ?
                            <div className="addProductPanel">
                                <Form className="panel" onSubmit={e => {isEditting? handleSaveProduct(e) : handleSubmit(e)}}>
                                    <Image fluid className="productPhoto" src={productPhoto} alt="User" onClick={() => handleUploadImageClick()} />
                                    <Form.Group controlId="photo" className="mb-3">
                                        <Form.Control onChange={(e) => handleUploadImage(e)} style={{ display: "none" }} type="file" size="sm" ref={refImageUploader} />
                                        {progress > 0 && progress < 100 ? <ProgressBar variant="info" animated now={progress} /> : null}
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="name">
                                        <Form.Label>Nome do Produto</Form.Label>
                                        <Form.Control name="name" required type="text" placeholder="Digite o nome do Produto" ref={productNameRef} />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="price">
                                        <Form.Label>Preço</Form.Label>
                                        <Form.Control name="price" required type="text" placeholder="R$ 0,00" ref={productPriceRef} />
                                    </Form.Group>

                                    <div className="d-grid gap-2">
                                        {isEditting ?
                                            <Button className="saveButton" type="submit">
                                                {isSubmiting === true ? <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                /> : null} Salvar produto
                                            </Button>
                                            :
                                            <Button className="saveButton" type="submit">
                                                {isSubmiting === true ? <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                /> : null} Adicionar produto
                                            </Button>
                                        }

                                    </div>
                                </Form>

                            </div>
                            :
                            null
                        }
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                <h3 className="productsTitle">Todos os produtos</h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Table className="productList" striped borderless hover>
                                    <thead>
                                        <tr>
                                            <th>Imagem</th>
                                            <th>Nome</th>
                                            <th>Preço</th>
                                            <th>Ações</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props.products.map(product => {
                                            return (
                                                <tr>
                                                    <td className="fit"><Image width="100" height="100" fluid className="productPhoto"
                                                        src={product.photo} alt={product.name} /></td>
                                                    <td>{product.name}</td>
                                                    <td className="fit">{product.price}</td>
                                                    <td className="fit">
                                                        <Button className="productButton" onClick={() => handleEdit(product)}>
                                                            <FontAwesomeIcon icon={faPencilAlt} />
                                                        </Button>
                                                        <Button className="productButton" onClick={() => handleDelete(product.id)}>
                                                            <FontAwesomeIcon icon={faTrash} />
                                                        </Button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>

                            </Col>
                        </Row></Col>
                </Row>



            </Container>
        </div>
    )
}
