import React from "react";
import { Container, Col, Row, Card, Button } from "react-bootstrap";
import "./Catalog.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGift } from "@fortawesome/free-solid-svg-icons";

function handleCommButton(product) {
  let whatsappNumber = "5521997839904";
  let messageTemplate = encodeURIComponent(
    `Olá, gostaria de fazer o pedido do produto ${product.name}.`
  );
  window.open(`https://wa.me/${whatsappNumber}?text=${messageTemplate}`);
}

export default function Catalog(props) {
  return (
    <Container>
      <Row>
        <Col>
          <h3 className="sectionTitle">Todos os produtos</h3>
        </Col>
      </Row>
      <div className="productDeck">
        {props.products.map((product, index) => {
          return (
            <Card key={index} className="productCard">
              <Card.Img variant="top" src={product.photo} />
              <Card.Body>
                <Card.Title>
                  <span className="productName">{product.name}</span>
                </Card.Title>
                <Card.Text>
                  <span className="productPrice">{product.price}</span>
                </Card.Text>
                <Button
                  className="productButton"
                  onClick={() => handleCommButton(product)}>
                  <FontAwesomeIcon icon={faGift} /> Quero isso!
                </Button>
              </Card.Body>
            </Card>
          );
        })}
      </div>
    </Container>
  );
}
