
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
    apiKey: "AIzaSyDGSU8iqay91WGHmAh4k0TRMpln4sImmhQ",
    authDomain: "presentin-f5481.firebaseapp.com",
    projectId: "presentin-f5481",
    storageBucket: "presentin-f5481.appspot.com",
    messagingSenderId: "788113456966",
    appId: "1:788113456966:web:5d54a9d93bd6126454ef47"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app)

export const storage = getStorage(app)

export default db;
export { app } ;