import { createContext, useContext, useEffect, useState } from "react";
import { getAuth, signInWithPopup, GoogleAuthProvider, signOut, onAuthStateChanged } from "@firebase/auth";
import { app } from "../firebase";

const AuthContext = createContext({
    currentUser: null,
    signInWithGoogle: () => Promise,
    logout: () => Promise
})

export const useAuth = () => useContext(AuthContext)

export default function AuthContextProvider({ children }) {

    const auth = getAuth(app)

    const [currentUser, setCurrentUser] = useState(null)

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, user => {
            setCurrentUser(user ? user : null)
        })
        return () => {
            unsubscribe()
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
    }, [currentUser])


    function signInWithGoogle() {
        const provider = new GoogleAuthProvider();
        return signInWithPopup(auth, provider)
    }

    function logout() {
        return signOut(auth)
    }

    const value = {
        currentUser,
        signInWithGoogle,
        logout
    }

    return <AuthContext.Provider value={value}>
        {children}
    </AuthContext.Provider>
}