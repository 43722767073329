import { useState, useEffect} from 'react';
import Catalog from './components/Catalog/Catalog';
import CatalogEditor from './components/CatalogEditor/CatalogEditor';
import Login from './components/Login/Login';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom'
import db from './firebase';
import { collection, query, onSnapshot } from 'firebase/firestore'
import { Navbar, Container } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from './logo.svg'
import './App.css'
import AuthContextProvider from './contexts/AuthContext';
import { useAuth } from './contexts/AuthContext';

const ProtectedRoute = ({component: Component, ...rest}) => {
  let { currentUser } = useAuth()
  return (
    <Route
      {...rest}
      render={props => {
        if (currentUser) {
          return <Component {...props} products={rest.products}/>;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location
                }
              }}
            />
          );
        }
      }}
    />
  );
};


function App() {
  const [products, setProducts] = useState([])

  const fetchProjects = async () => {
    const q = query(collection(db, 'products'));
    onSnapshot(q, (querySnapshot) => {
      let productsData = []
      querySnapshot.forEach((doc) => {
        productsData.push(doc.data())
      })
      setProducts(productsData)
    })
  }

  useEffect(() => {
    fetchProjects()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps


  function Home() {
    return (
      <div>
        <Navbar variant="light" className="navbarWrapper">
          <Container>
            <img
              alt="Presentin"
              src={Logo}
              width="120"
              height="40"
            />
            <span className="tagLine">Escolha seu produto.</span>
          </Container>
        </Navbar>
        <Catalog products={products} />
        <div className="pageFooter"><p>Presentin 2021. Todos os direitos reservados.</p></div>
      </div>
    )
  }

  return (
    <AuthContextProvider>
      <BrowserRouter>
        <div className="App">
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/login" component={Login} />
            <ProtectedRoute exact path="/catalog-editor" component={CatalogEditor} products={products}/>
          </Switch>
        </div>
      </BrowserRouter>
    </AuthContextProvider >
  );
}

export default App;
